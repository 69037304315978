import React, { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";
import { useFormik } from "formik";
import { Label } from "reactstrap";

import { CustomModal } from "../../components/CustomModal";
import { TextInput } from "../../components/Input";
import { SkillLevelArticleOptions, TopicOptionsList } from "../../constants";
import { AutoComplete } from "../../components/AutoComplete";
import { LABLES, PLACEHOLDERS, NAMES } from "./ContentFieldsNames";
import { CustomButton } from "../../components/CustomButton";
import { ImageUploader } from "../../components/ImageUploader";
import { MODULE_KEYS, getKey } from "../../api/api.keys";
import { updateContentFormValidationSchema, initialValues, validationMessagesImage } from "./FormValidations";
import { useRequestProcessor } from "../../hooks/useRequestProcessors";
import { CreateArticle } from "../../api/api.service";
import { showToast, SUCCESS, ERROR } from "../../utils/toastUtils";
import { uploadMediaToServer } from "../../utils/mediaUtils";
import FormikErrorText from "../../components/FormikErrorText/FormikErrorText";
import { customStyles } from "./CustomStyles";
import { findSkillLevelValueForAPI } from "./utils";

let { ARTICLE_NAME, WEBSITE_NAME, WEBSITE_LINK, TEASER_TEXT, SUBTITLES, SKILL_LEVEL, TOPIC } = LABLES;

const AddContent = ({ open = false, onCreate = () => {}, onCancel = () => {} }) => {
    const [contentImage, setContentImage] = useState("");
    const [imageValidationError, setImageValidationError] = useState(false)
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);

    // reinitialing formik
    useEffect(() => {
        return () => {
            formik.resetForm();
        };
    }, [open]);

    useEffect(() => {
        if(contentImage) setImageValidationError(false)
    }, [contentImage]);

    let { useMutate } = useRequestProcessor();
    let { mutate } = useMutate(
        MODULE_KEYS.CONTENT_MANAGEMENT_LISTING,
        getKey([MODULE_KEYS.CONTENT_MANAGEMENT_CREATE]),
        (data) => CreateArticle(data)
    );

    // handle fields validation using formik
    const formik = useFormik({
        initialValues: initialValues.updateContent,
        enableReinitialize: true,
        validationSchema: updateContentFormValidationSchema,
        onSubmit: (values, { resetForm }) => {
            handleFormSubmit(values, resetForm);
        }
    });

    // handle formsubmit
    const handleFormSubmit = async (values) => {
        setIsFormSubmitting(true);
        if (contentImage === "") {
            if(!contentImage) setImageValidationError(true)
            // showToast(ERROR, "kindly upload Article Image.");
            setIsFormSubmitting(false);
            return;
        }
        // uploading image on bucket
        let imageId = await uploadMediaToServer(contentImage, setIsFormSubmitting);
        const bodyData = {
            Title: values[NAMES[ARTICLE_NAME]],
            Description: values[NAMES[TEASER_TEXT]],
            MediaId: imageId,
            Url: values[NAMES[WEBSITE_LINK]],
            WebsiteName: values[NAMES[WEBSITE_NAME]],
            Subtitle: values[NAMES[SUBTITLES]],
            SkillLevel: findSkillLevelValueForAPI(values[NAMES[SKILL_LEVEL]]),
            Topic: values[NAMES[TOPIC]]
        }; 
        mutate(bodyData, {
            onSuccess: (res) => createContentSuccessHandler(),
            onError: (err) => createContentErrorHandler(err)
        });
    };

    // handle api success response
    const createContentSuccessHandler = () => {
        setIsFormSubmitting(false);
        showToast(SUCCESS, "Article has been created successfully");
        onCreate();
    };
    // handle api failure response
    const createContentErrorHandler = (error) => {
        setIsFormSubmitting(false);
        // showToast(ERROR, error?.Message)
    };

    return (
        <div>
            <CustomModal isOpen={open} contentClassName="p-4 pt-0" size="lg">
                <form onSubmit={formik.handleSubmit}>
                    <Row className="my-4">
                        <Col sm="12" md="6" lg="6" xl="6">
                            <TextInput
                                inputClasses="content-input"
                                label={ARTICLE_NAME}
                                name={NAMES[ARTICLE_NAME]}
                                placeholder={PLACEHOLDERS[ARTICLE_NAME]}
                                value={formik.values[NAMES[ARTICLE_NAME]]}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            <FormikErrorText
                                formikInstance={formik}
                                fieldName={[NAMES[ARTICLE_NAME]]}
                            />
                        </Col>
                        <Col sm="12" md="6" lg="6" xl="6">
                            <TextInput
                                inputClasses="content-input"
                                label={WEBSITE_NAME}
                                name={NAMES[WEBSITE_NAME]}
                                placeholder={PLACEHOLDERS[WEBSITE_NAME]}
                                value={formik.values[NAMES[WEBSITE_NAME]]}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            <FormikErrorText
                                formikInstance={formik}
                                fieldName={[NAMES[WEBSITE_NAME]]}
                            />
                        </Col>
                    </Row>
                    <Row className="my-4">
                        <Col sm="12" md="6" lg="6" xl="6">
                            <TextInput
                                inputClasses="content-input"
                                label={WEBSITE_LINK}
                                name={NAMES[WEBSITE_LINK]}
                                placeholder={PLACEHOLDERS[WEBSITE_LINK]}
                                value={formik.values[NAMES[WEBSITE_LINK]]}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            <FormikErrorText
                                formikInstance={formik}
                                fieldName={[NAMES[WEBSITE_LINK]]}
                            />
                        </Col>
                        <Col sm="12" md="6" lg="6" xl="6">
                            <Label className="mb-2 skill-level-label fw-bold">Skill Level</Label>
                            <AutoComplete
                                styles={customStyles.skillLevel}
                                onChange={(val) => formik.setFieldValue(NAMES[SKILL_LEVEL], val.label)}
                                onBlur={() => {
                                    formik.setFieldTouched(NAMES[SKILL_LEVEL]);
                                }}
                                value={SkillLevelArticleOptions.filter(
                                    (el) => el.label === formik.values[NAMES[SKILL_LEVEL]]
                                )}
                                name={NAMES[SKILL_LEVEL]}
                                options={SkillLevelArticleOptions}
                                placeholder={SKILL_LEVEL}
                                isSearchable={false}
                            />
                            <FormikErrorText
                                formikInstance={formik}
                                fieldName={[NAMES[SKILL_LEVEL]]}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <Label className="mb-2 skill-level-label fw-bold">{TOPIC}</Label>
                            <AutoComplete
                                styles={customStyles.skillLevel}
                                onChange={(val) => formik.setFieldValue(NAMES[TOPIC], val.label)}
                                onBlur={() => {
                                    formik.setFieldTouched(NAMES[TOPIC]);
                                }}
                                value={TopicOptionsList.filter(
                                    (el) => el.label === formik.values[NAMES[TOPIC]]
                                )}
                                name={NAMES[TOPIC]}
                                options={TopicOptionsList}
                                placeholder={TOPIC}
                                isSearchable={false}
                            />
                            <FormikErrorText
                                formikInstance={formik}
                                fieldName={[NAMES[TOPIC]]}
                            />
                        </Col>
                    </Row>
                    <Row className="my-4">
                        <Col sm="12" md="6" lg="6" xl="6">
                            <TextInput
                                inputClasses="content-input"
                                label={SUBTITLES}
                                name={NAMES[SUBTITLES]}
                                placeholder={PLACEHOLDERS[SUBTITLES]}
                                value={formik.values[NAMES[SUBTITLES]]}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                type="textarea"
                            />
                            <FormikErrorText
                                formikInstance={formik}
                                fieldName={[NAMES[SUBTITLES]]}
                            />
                        </Col>
                        <Col sm="12" md="6" lg="6" xl="6">
                            <TextInput
                                inputClasses="content-input"
                                label={TEASER_TEXT}
                                name={NAMES[TEASER_TEXT]}
                                placeholder={PLACEHOLDERS[TEASER_TEXT]}
                                type="textarea"
                                value={formik.values[NAMES[TEASER_TEXT]]}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            <FormikErrorText
                                formikInstance={formik}
                                fieldName={[NAMES[TEASER_TEXT]]}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col sm="12" md="12" lg="12" className="my-4">
                            <ImageUploader 
                            label={"Ad Image"} 
                            setContentImage={setContentImage} 
                            imageValidtion={imageValidationError &&  validationMessagesImage?.requiredError}/>
                        </Col>
                    </Row>

                    <div className="content-modal-resopnsiveness d-flex justify-content-lg-start justify-content-md-start justify-content-sm-center flex-wrap mt-5 mb-4">
                        <CustomButton
                            title="Create"
                            color="primary"
                            className="custom-btn-lg"
                            type={"submit"}
                            loading={isFormSubmitting}
                        />
                        <CustomButton
                            title="Cancel"
                            type="button"
                            outline
                            color="primary"
                            className="custom-btn-lg-secondary mx-md-2 mx-lg-2"
                            onClick={onCancel}
                            disabled={isFormSubmitting}
                        />
                    </div>
                </form>
            </CustomModal>
        </div>
    );
};

export default AddContent;
