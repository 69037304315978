import { injectBaseConstantMethods } from "./BaseConstants";

export const JOB_TYPES = {
    Audit: "auditor",
    Merchandising: "merchandiser",
    Resetting: "resetter",
    Team_Lead: "team_lead"
};

const displayTextKeys = {
    [JOB_TYPES.Audit]: "Auditor",
    [JOB_TYPES.Merchandising]: "Merchandiser",
    [JOB_TYPES.Resetting]: "Resetter",
    [JOB_TYPES.Team_Lead]: "Team Lead"
};

const labelClass = {
    [JOB_TYPES.Audit]: "",
    [JOB_TYPES.Merchandising]: "",
    [JOB_TYPES.Resetting]: ""
};

export default injectBaseConstantMethods(JOB_TYPES, displayTextKeys, labelClass);
