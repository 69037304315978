import React from "react";
import { Card, CardBody, Row, Col, Form } from "reactstrap";

import { CustomText } from "../../components/CustomText";
import { CustomButton } from "../../components/CustomButton";
import GoogleAutocomplete from "../../components/GoogleAutocomplete/GoogleAutocomplete";
import { MAP_OPTIONS } from "../../constants/MapOptions";
import { EditableDetail } from "../../components/EditableDetail";
import FormikErrorText from "../../components/FormikErrorText/FormikErrorText";
import { useFormik } from "formik";
import { validationSchema } from "./FormConfig";
import { TextInput } from "../../components/Input";

let EDIT_ICON = <span className="bx bx-edit-alt icon-grey"></span>;
const COL_SIZE_FOUR = "4";
const COL_SIZE_TWELVE = "12";
const ContentDataStructureUI = ({
    isUpdate = false,
    isDetailView = false,
    isLoading = false,
    FIELDS_TITLE = {},
    FIELDS_NAME = {},
    onCancel = () => {},
    onSave = () => {},
    onEdit = () => {},
    handlePlaceSelect = () => {},
    data = {},
    fieldState = "",
    children
}) => {
    let {
        COURSE_NAME,
        ADDRESS,
        CITY,
        COURSE_FEE,
        PARKING,
        BASKETS,
        TEE_PADS,
        NOTES,
        OPERATION_TIMINGS,
        COURSE_PROFILE_LINK
    } = FIELDS_TITLE;

    const defaultFormData = {
        [FIELDS_NAME[COURSE_NAME]]: data?.Name || "",
        [FIELDS_NAME[ADDRESS]]: data?.Address || "",
        [FIELDS_NAME[CITY]]: data?.City || "",
        [FIELDS_NAME[COURSE_FEE]]: data?.Fee || 0,
        [FIELDS_NAME[PARKING]]: data?.Parking || "",
        [FIELDS_NAME[BASKETS]]: data?.Holes || "",
        [FIELDS_NAME[TEE_PADS]]: data?.TeePads || "",
        [FIELDS_NAME[NOTES]]: data?.Notes || "",
        [FIELDS_NAME[COURSE_PROFILE_LINK]]: data?.PdgaProfile || ""
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: { ...defaultFormData },
        validationSchema,
        onSubmit: (values) => {
            onSave(values);
        }
    });
    const checkKeyDown = (e) => {
        if (e.key === 'Enter') e.preventDefault();
      };
    const colNotesResp = isUpdate ? COL_SIZE_TWELVE : COL_SIZE_FOUR;
    return (
        <Card className="cutom-card card-shadow">
            <CardBody>
                <Form onSubmit={formik.handleSubmit}  onKeyDown={(e) => checkKeyDown(e)}>
                    {children}
                    <Row>
                        <Col sm="12" md="4" lg="4" xl="4">
                            <EditableDetail
                                inputClasses="course-input"
                                isUpdate={isUpdate}
                                isDetails={isDetailView}
                                title={COURSE_NAME}
                                name={FIELDS_NAME[COURSE_NAME]}
                                value={formik.values[FIELDS_NAME[COURSE_NAME]]}
                                onChange={formik.handleChange}
                                onBlur={formik.handleChange}
                            />
                            <FormikErrorText
                                formikInstance={formik}
                                fieldName={[FIELDS_NAME[COURSE_NAME]]}
                            />
                        </Col>
                        <Col sm="12" md="4" lg="4" xl="4" className="mt-4 mt-md-0">
                            {isUpdate ? (
                                <>
                                    <GoogleAutocomplete
                                        label={ADDRESS}
                                        inputStyles="course-input"
                                        fieldName={FIELDS_NAME[ADDRESS]}
                                        name={FIELDS_NAME[ADDRESS]}
                                        onLocationSelect={(place) =>
                                            handlePlaceSelect(
                                                place,
                                                formik,
                                                FIELDS_NAME[ADDRESS],
                                                FIELDS_NAME[CITY]
                                            )
                                        }
                                        value={formik.values[FIELDS_NAME[ADDRESS]]}
                                        onChange={formik.handleChange}
                                        handleOnBlur={(e) => {}}
                                        componentRestrictions={MAP_OPTIONS}
                                    />
                                    <FormikErrorText
                                        formikInstance={formik}
                                        fieldName={[FIELDS_NAME[ADDRESS]]}
                                    />
                                </>
                            ) : (
                                <CustomText
                                    title={ADDRESS}
                                    description={data?.Address ? data?.Address : "-"}
                                />
                            )}
                        </Col>
                        <Col sm="12" md="4" lg="4" xl="4" className="mt-4 mt-md-0">
                            <EditableDetail
                                inputClasses="course-input"
                                isUpdate={isUpdate}
                                isDetails={isDetailView}
                                title={CITY}
                                name={FIELDS_NAME[CITY]}
                                value={formik.values[FIELDS_NAME[CITY]]}
                                onChange={formik.handleChange}
                                onBlur={formik.handleChange}
                                disabled={fieldState}
                            />
                            <FormikErrorText
                                formikInstance={formik}
                                fieldName={[FIELDS_NAME[CITY]]}
                            />
                        </Col>
                    </Row>

                    {/* <Row className="mt-4 mb-0 mb-md-5 mt-md-5"> */}
                    {/* <Col sm="12" md="4" lg="4" xl="4" >
                            {isUpdate ? (
                                <>
                                    <TextInput
                                        inputClasses="course-input"
                                        isUpdate={isUpdate}
                                        isDetails={isDetailView}
                                        label={COURSE_FEE}
                                        name={FIELDS_NAME[COURSE_FEE]}
                                        value={formik.values[FIELDS_NAME[COURSE_FEE]]}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleChange}
                                    />
                                    <FormikErrorText
                                        formikInstance={formik}
                                        fieldName={[FIELDS_NAME[COURSE_FEE]]}
                                    />
                                </>
                            ) : (
                                <CustomText
                                    title={COURSE_FEE}
                                    description={`$${data?.Fee?.toFixed(2)}` || "-"}
                                />
                            )}
                        </Col>
                        <Col sm="12" md="4" lg="4" xl="4" className="mt-4 mt-md-0">
                            <EditableDetail
                                inputClasses="course-input"
                                isUpdate={isUpdate}
                                isDetails={isDetailView}
                                title={PARKING}
                                name={FIELDS_NAME[PARKING]}
                                value={formik.values[FIELDS_NAME[PARKING]]}
                                onChange={formik.handleChange}
                                onBlur={formik.handleChange}
                            />
                            <FormikErrorText
                                formikInstance={formik}
                                fieldName={[FIELDS_NAME[PARKING]]}
                            />
                        </Col> */}
                    {/* <Col sm="12" md="4" lg="4" xl="4">
                            {isUpdate ? (
                                <>
                                    <TextInput
                                        inputClasses="course-input"
                                        icon={EDIT_ICON}
                                        label={OPERATION_TIMINGS}
                                        name={FIELDS_NAME[OPERATION_TIMINGS]}
                                        value={formik.values.operationTimings}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.operationTimings &&
                                    formik.errors.operationTimings ? (
                                        <span className="error fs-10">
                                            {formik.errors.operationTimings}
                                        </span>
                                    ) : null}
                                </>
                            ) : (
                                <CustomText
                                    title={OPERATION_TIMINGS}
                                    description={
                                        courseDetail?.Timings.length > 0 ? (
                                            <div>
                                                {`${courseDetail.Timings[0]} - ${courseDetail.Timings[1]}`}
                                            </div>
                                        ) : (
                                            "-"
                                        )
                                    }
                                />
                            )}
                        </Col> */}
                    {/* </Row> */}

                    <Row className="mt-4 mb-0 mb-md-5 mt-md-5">
                        <Col sm="12" md="4" lg="4" xl="4" className="mt-4 mt-md-0">
                            <EditableDetail
                                inputClasses="course-input"
                                isUpdate={isUpdate}
                                isDetails={isDetailView}
                                title={BASKETS}
                                name={FIELDS_NAME[BASKETS]}
                                value={formik.values[FIELDS_NAME[BASKETS]]}
                                onChange={formik.handleChange}
                                onBlur={formik.handleChange}
                            />
                            <FormikErrorText
                                formikInstance={formik}
                                fieldName={[FIELDS_NAME[BASKETS]]}
                            />
                        </Col>
                        <Col sm="12" md="4" lg="4" xl="4" className="mt-4 mt-md-0">
                            <EditableDetail
                                inputClasses="course-input"
                                isUpdate={isUpdate}
                                isDetails={isDetailView}
                                title={TEE_PADS}
                                name={FIELDS_NAME[TEE_PADS]}
                                value={formik.values[FIELDS_NAME[TEE_PADS]]}
                                onChange={formik.handleChange}
                                onBlur={formik.handleChange}
                            />
                            <FormikErrorText
                                formikInstance={formik}
                                fieldName={[FIELDS_NAME[TEE_PADS]]}
                            />
                        </Col>
                    </Row>
                    <Row className="mt-4 mb-0 mb-md-5 mt-md-5">
                        <Col sm="12" md={colNotesResp} lg={colNotesResp} xl={colNotesResp}>
                            {isUpdate ? (
                                <>
                                    <EditableDetail
                                        inputClasses="course-input"
                                        isUpdate={isUpdate}
                                        isDetails={isDetailView}
                                        title={COURSE_PROFILE_LINK}
                                        name={FIELDS_NAME[COURSE_PROFILE_LINK]}
                                        value={formik.values[FIELDS_NAME[COURSE_PROFILE_LINK]]}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleChange}
                                    />
                                    <FormikErrorText
                                        formikInstance={formik}
                                        fieldName={[FIELDS_NAME[COURSE_PROFILE_LINK]]}
                                    />
                                </>
                            ) : (
                                <CustomText
                                    title={COURSE_PROFILE_LINK}
                                    description={
                                        data?.PdgaProfile?.length > 0 ? (
                                            <a href={data?.PdgaProfile} target="_blank">
                                                {data?.PdgaProfile}
                                            </a>
                                        ) : (
                                            "-"
                                        )
                                    }
                                />
                            )}
                        </Col>
                    </Row>
                    <Row className="my-4">
                        <Col sm="12" md={colNotesResp} lg={colNotesResp} xl={colNotesResp}>
                            <EditableDetail
                                inputClasses="course-input"
                                type="textarea"
                                icon={false}
                                isUpdate={isUpdate}
                                isDetails={isDetailView}
                                title={NOTES}
                                name={FIELDS_NAME[NOTES]}
                                value={formik.values[FIELDS_NAME[NOTES]]}
                                onChange={formik.handleChange}
                                onBlur={formik.handleChange}
                            />
                            <FormikErrorText
                                formikInstance={formik}
                                fieldName={[FIELDS_NAME[NOTES]]}
                            />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col sm="12" md="12" lg="12" className="course-mang-edit-btn-margin">
                            {isDetailView && (
                                <CustomButton
                                    title="Edit"
                                    color="primary"
                                    className="custom-btn-lg mt-3"
                                    onClick={onEdit}
                                />
                            )}
                        </Col>
                        {isUpdate && (
                            <div className="mt-3 d-flex justify-content-start flex-column flex-sm-row">
                                <CustomButton
                                    title="Save Changes"
                                    color="primary"
                                    className="custom-btn-lg"
                                    type="submit"
                                    loading={isLoading}
                                />
                                <CustomButton
                                    title="Cancel"
                                    outline
                                    color="primary"
                                    className="custom-btn-lg-secondary  mx-0 mx-sm-2 mt-3 mt-sm-0"
                                    onClick={onCancel}
                                />
                            </div>
                        )}
                    </Row>
                </Form>
            </CardBody>
        </Card>
    );
};

export default ContentDataStructureUI;
