import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import { useHistory } from "react-router-dom";

import suspendModalImg from "../../assets/images/app/common/modal-suspend-img.svg";
import deleteModalImg from "../../assets/images/app/common/modal-delete-img.svg";
import ProfileHeader from "./ProfileHeader";
import ProfileContent from "./ProfileContent";
import { LoaderFallbackUI } from "../../components/LoaderFallbackUI";
import { ConfirmationModal } from "../../components/CustomModal";
import { PageTitles } from "../../constants";
import { useRequestProcessor } from "../../hooks/useRequestProcessors";
import { DeleteUserById, GetUserById, UpdateUser } from "../../api/api.service";
import { getKey } from "../../api/api.keys";
import { MODULE_KEYS } from "../../api/api.keys";
import { hasData, joinArrayItems } from "../../utils/commonUtils";
import { showToast, SUCCESS, ERROR } from "../../utils/toastUtils";
import { APP_ROUTES } from "../../helpers/routeHelpers";

let ACTIONS = {
    ACTIVE: 1,
    SUSPEND: 0
};

let USER_STATUS = {
    VERIFIED: "Verified",
    SUSPENDED: "Suspended"
};

let MODAL_CONTENT_CONFIG = {
    [ACTIONS.SUSPEND]: {
        modalTitle: "Are you sure you want to suspend this user?",
        yesButtonText: "Yes",
        noButtonText: "Cancel",
        imageSrc: suspendModalImg
    },
    [ACTIONS.ACTIVE]: {
        modalTitle: "Are you sure you want to activate this user?",
        yesButtonText: "Yes",
        noButtonText: "Cancel",
        imageSrc: suspendModalImg
    }
};

let ACTION_BUTTON_CONFIG = {
    [ACTIONS.SUSPEND]: {
        buttonTitle: "Suspended",
        btnColor: "custom-btn-action-red",
        btnIcon: <span class="bx bx-block" />
    },
    [ACTIONS.ACTIVE]: {
        buttonTitle: "Active",
        btnColor: "custom-btn-action-green",
        btnIcon: <span className="bx bx-check-circle" />
    }
};

let PREFERENCES = {
    NAME: "PreferenceName",
    VALUE: "PreferenceValues"
};

const Profile = () => {
    let { userId } = useParams();
    let history = useHistory();
    const [userStatus, setUserStatus] = useState(ACTIONS.ACTIVE);
    const [isUserStatusUpdating, setIsUserStatusUpdating] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [preferences, setPreferences] = useState({});

    const [isDeleteUser, setIsDeleteUser] = useState(false);
    const [isUserDeleteLoading, setIsUserDeleteLoading] = useState(false);

    let { useFetch, useMutate } = useRequestProcessor();
    let { data, isLoading, isFetching } = useFetch(
        getKey([MODULE_KEYS.USER_DETAILS_PROFILE]),
        async ({ signal }) => await GetUserById(userId, {}, signal),
        {
            onSuccess: (res) => modifyAPIData(res)
        }
    );

    // handling article delete API
    let { mutate: userDeleteMutate } = useMutate("", getKey(), (data) =>
        DeleteUserById(userId, data)
    );

    const modifyAPIData = (data) => {
        let modifiedPreferences = {};
        if (hasData(data)) {
            modifiedPreferences = data?.Preferences?.reduce((acc, curr) => {
                if (curr[PREFERENCES.NAME] === "BestDays") {
                    curr[PREFERENCES.VALUE] = curr[PREFERENCES.VALUE].map((el) =>
                        el.substring(0, 3)
                    );
                }
                return { ...acc, [curr[PREFERENCES.NAME]]: curr[PREFERENCES.VALUE] };
            }, {});
        }
        let userStatus = data?.Status === USER_STATUS.VERIFIED ? ACTIONS.ACTIVE : ACTIONS.SUSPEND;
        setUserStatus(userStatus);
        setPreferences(modifiedPreferences);
    };
    let { mutate } = useMutate("", getKey([MODULE_KEYS.UPDATE_USER]), (data) => UpdateUser(data));
    const handleActionInit = async () => {
        let statusToSet = getStatus();
        setModalContent(MODAL_CONTENT_CONFIG[statusToSet]);
    };

    const handleActionConfirmation = () => {
        setIsUserStatusUpdating(true);
        let statusToSet = getStatus();
        let userStatus =
            statusToSet === ACTIONS.ACTIVE ? USER_STATUS.VERIFIED : USER_STATUS.SUSPENDED;
        const bodyData = { Status: userStatus };
        // Api call here
        mutate(
            { id: userId, bodyData },
            {
                onSuccess: async (res) => {
                    setIsUserStatusUpdating(false);
                    showToast(SUCCESS, "Status has been updated successfully");
                    setUserStatus(statusToSet);
                    setModalContent(null);
                },
                onError: (err) => {
                    // showToast(ERROR, err?.Message)
                    setIsUserStatusUpdating(false);
                }
            }
        );
    };

    const handleActionCancellation = () => {
        setModalContent(null);
    };

    const getStatus = () => (userStatus == ACTIONS.ACTIVE ? ACTIONS.SUSPEND : ACTIONS.ACTIVE);

    const handleDelete = () => {
        setIsDeleteUser(true);
    };

    const handleDeleteUser = () => {
        setIsUserDeleteLoading(true);
        // Api call here
        userDeleteMutate(
            {},
            {
                onSuccess: async (res) => {
                    setIsUserDeleteLoading(false);
                    setIsDeleteUser(false);
                    showToast(SUCCESS, "User has been deleted successfully");
                    history.push(APP_ROUTES.USER_MANAGEMENT);
                },
                onError: (err) => {
                    setIsUserDeleteLoading(false);
                    setIsDeleteUser(false);
                    showToast(ERROR, err?.Message);
                }
            }
        );
    };

    if (isFetching || isLoading || !hasData(data)) {
        return (
            <div style={{ height: "70vh" }}>
                <LoaderFallbackUI isLoader={isFetching} className={"h-100"} />
            </div>
        );
    }
    return (
        <div className=" mt-4">
            <Helmet>
                <title>{PageTitles.USER_MANAGEMENT}</title>
            </Helmet>
            {
                <>
                    <Row>
                        <Col xxl={12} md={12}>
                            <Row>
                                <Col xs={12}>
                                    <Card className="overflow-hidden">
                                        <CardBody className="p-0">
                                            <ProfileHeader
                                                skillLevel={data?.SkillLevel || "-"}
                                                name={data?.FullName || "-"}
                                                onActionClick={handleActionInit}
                                                userStatus={userStatus}
                                                config={modalContent}
                                                img={data?.ProfileImage || ""}
                                                handleDelete={handleDelete}
                                                buttonConfig={ACTION_BUTTON_CONFIG[userStatus]}
                                            />
                                            <ProfileContent
                                                address={data?.HomeCourse?.Address || "-"}
                                                email={data?.Email || "-"}
                                                pdgaRating={joinArrayItems(preferences?.PdgaRating)}
                                                primaryClub={data?.HomeCourse?.Name || "-"}
                                                bestDays={joinArrayItems(preferences?.BestDays)}
                                                bestTime={joinArrayItems(preferences?.BestTimes)}
                                                dominantThrow={joinArrayItems(
                                                    preferences?.DominantThrow
                                                )}
                                                favouriteDisc={joinArrayItems(
                                                    preferences?.FavoriteDisk
                                                )}
                                                roundPerWeek={joinArrayItems(
                                                    preferences?.RoundsPerWeek
                                                )}
                                                referredRounds={joinArrayItems(
                                                    preferences?.RoundType
                                                )}
                                                goals={joinArrayItems(preferences?.Goals)}
                                                homeCourse={data?.HomeCourse?.Name || "-"}
                                                zipcode={joinArrayItems(preferences?.ZipCode)}
                                                playingSince={data?.PlayingSince || "-"}
                                                pdgaNumber={joinArrayItems(preferences?.PdgaNumber)}
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <ConfirmationModal
                        size="md"
                        hasCustomConfig
                        isOpen={modalContent}
                        customImageSrc={modalContent?.imageSrc}
                        customTitle={modalContent?.modalTitle}
                        customYesBtnText={modalContent?.yesButtonText}
                        customNoBtnText={modalContent?.noButtonText}
                        onNo={handleActionCancellation}
                        onYes={handleActionConfirmation}
                        yesLoading={isUserStatusUpdating}
                    />

                    <ConfirmationModal
                        isOpen={isDeleteUser}
                        onYes={handleDeleteUser}
                        yesLoading={isUserDeleteLoading}
                        size="md"
                        onNo={() => setIsDeleteUser(false)}
                    />
                </>
            }
        </div>
    );
};

export default Profile;
