import { injectBaseConstantMethods } from "./BaseConstants";

export const JOBS_PAY_TYPE = {
    FixedPrice: 1,
    Hourly: 2
};

const displayTextKeys = {
    [JOBS_PAY_TYPE.FixedPrice]: "Fixed Price",
    [JOBS_PAY_TYPE.Hourly]: "Hourly"
};

const labelClass = {
    [JOBS_PAY_TYPE]: ""
};

export default injectBaseConstantMethods(JOBS_PAY_TYPE, displayTextKeys, labelClass);
