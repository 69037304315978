import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from "reactstrap";
import { useHistory } from "react-router-dom";

import { SearchBox } from "../../components/SearchBox";
import { CustomButton } from "../../components/CustomButton";
import { Pagination } from "../../components/Pagination";
import { DataTable } from "../../components/DataTable";
import AddCourse from "./AddCourse";
import { APP_ROUTES, getParamsAttachedRoute } from "../../helpers/routeHelpers";
import { GetCourses } from "../../api/api.service";
import { MODULE_KEYS } from "../../api/api.keys";
import { useLocalPaginatedRequest } from "../../hooks/useLocalPaginatedRequest";
import { PageTitles, SortOrder } from "../../constants";
import viewIcon from "../../assets/images/app/common/listing-view-image.svg";
import editIcon from "../../assets/images/app/common/listing-edit-image.svg";
import { LABLES } from "./CourseFieldsName";
import { handleAdModal } from "../AdManagement/utils";
import { truncateString } from "../../utils/commonUtils";
import { CustomModal } from "../../components/CustomModal";
import ImportCoursesModal from "./ImportCoursesModal";

let {
    COURSE_NAME,
    ADDRESS,
    CITY,
    COURSE_FEE,
    PARKING,
    BASKETS,
    TEE_PADS,
    NOTES,
    OPERATION_TIMINGS,
    ACTION
} = LABLES;

const SORTABLE_COLUMNS = {
    [COURSE_NAME]: "Name",
    [ADDRESS]: "Address",
    [CITY]: "City",
    // [COURSE_FEE]: "Fee",
    [PARKING]: "",
    [BASKETS]: "",
    [TEE_PADS]: "",
    [NOTES]: "",
    [OPERATION_TIMINGS]: ""
};

const CourseManagement = () => {
    const history = useHistory();
    const [showAddCourseModal, setShowAddCourseModal] = useState(false);
    const [currentSortColumn, setCurrentSortColumn] = useState(null);
    const [currentSortOrder, setCurrentSortOrder] = useState(null);
    const [isImportCoursesModalActive, setIsImportCoursesModalActive] = useState(false);

    const {
        limit,
        isFetching,
        page,
        total,
        data,
        searchText,
        refetch,
        handlePageClick,
        handleSearchChange,
        handleSortingChange
    } = useLocalPaginatedRequest({
        key: MODULE_KEYS.COURSE_MANAGEMENT_LISTING,
        requestFn: GetCourses,
        params: {
            ...(currentSortColumn &&
                currentSortOrder && { Column: currentSortColumn, Direction: currentSortOrder })
        }
    });

    const handleSortClick = (item) => {
        setCurrentSortColumn(item.sortColumn);
        setCurrentSortOrder((prev) =>
            prev === SortOrder.ASC || !prev ? SortOrder.DESC : SortOrder.ASC
        );
    };

    const handleOnCancel = () => handleAdModal(setShowAddCourseModal, showAddCourseModal);
    const handleAddCourse = () => handleAdModal(setShowAddCourseModal, showAddCourseModal);

    const handleImoprtCourses = () => {
        setIsImportCoursesModalActive(true);
    };

    const handleImageUpload = () => {
        setIsImportCoursesModalActive(false);
    };
    const ImageUploadModalToggler = () => {
        setIsImportCoursesModalActive(!isImportCoursesModalActive);
    };

    return (
        <div className="page-content">
            <Helmet>
                <title>{PageTitles.COURSE_MANAGEMENT}</title>
            </Helmet>
            <Container fluid>
                <Row> 
                    <Col sm="12" md="3" lg="3">
                        <SearchBox
                            className="me-3 w-50"
                            value={searchText}
                            onChange={handleSearchChange}
                        />
                    </Col>
                    <Col sm="12" md="3" lg="3" className="my-2"></Col>
                    <Col sm="12" md="3" lg="3" align="right">
                        <CustomButton
                            title="Import Courses"
                            color="primary"
                            className="custom-btn-lg w-100 max-width-percent-modified"
                            onClick={handleImoprtCourses}
                        />
                    </Col>
                    <Col sm="12" md="3" lg="3" align="right">
                        <CustomButton
                            title="Add Course"
                            color="primary"
                            className="custom-btn-lg w-100 max-width-percent-modified"
                            onClick={handleAddCourse}
                        />
                    </Col>
                </Row>

                <DataTable
                    tableContainerClass="mt-3"
                    loading={isFetching}
                    data={data}
                    currentSortOrder={currentSortOrder}
                    currentSortColumn={currentSortColumn}
                    onSortClick={handleSortClick}
                    config={[
                        {
                            sortColumn: SORTABLE_COLUMNS[COURSE_NAME],
                            isSortable: true,
                            title: COURSE_NAME,
                            render: (data) => truncateString(data.Name, 30),
                        },
                        {
                            sortColumn: SORTABLE_COLUMNS[ADDRESS],
                            isSortable: true,
                            title: ADDRESS,
                            render: (data) => truncateString(data.Address, 30) || "-",
                        },

                        {
                            sortColumn: SORTABLE_COLUMNS[CITY],
                            isSortable: true,
                            title: CITY,
                            render: (data) => data.City || "-"
                        },
                        // {
                        //     sortColumn: SORTABLE_COLUMNS[COURSE_FEE],
                        //     isSortable: true,
                        //     title: COURSE_FEE,
                        //     render: (data) => <> ${data?.Fee?.toFixed(2) || "-"}</>
                        // },
                        {
                            title: ACTION,
                            render: (data) => {
                                return (
                                    <div className="d-flex">
                                        <img
                                            className="me-3"
                                            alt="detail icon"
                                            src={viewIcon}
                                            role="button"
                                            onClick={() =>
                                                history.push(
                                                    getParamsAttachedRoute(
                                                        APP_ROUTES.COURSE_MANAGEMENT_DETAILS,
                                                        {
                                                            courseId: data?.Id || 1
                                                        }
                                                    )
                                                )
                                            }
                                        />
                                        <img
                                            className="me-3"
                                            src={editIcon}
                                            alt="edit icon"
                                            role="button"
                                            onClick={() =>
                                                history.push(
                                                    getParamsAttachedRoute(
                                                        APP_ROUTES.UPDATE_COURSE,
                                                        {
                                                            courseId: data?.Id
                                                        }
                                                    )
                                                )
                                            }
                                        />
                                    </div>
                                );
                            }
                        }
                    ]}
                />
                <Pagination
                    rowLimit={limit}
                    onPageClick={handlePageClick}
                    currentPage={page}
                    totalData={total}
                    handleChange={(e) => handleSortingChange(e.target.value)}
                />
                <AddCourse
                    onCancel={handleOnCancel}
                    open={showAddCourseModal}
                    onCreate={handleAddCourse}
                />
            </Container>
            <CustomModal
                isOpen={isImportCoursesModalActive}
                toggle={ImageUploadModalToggler}
                size="md"
            >
                <ImportCoursesModal
                    onNo={() => setIsImportCoursesModalActive(false)}
                    refetchListing={refetch}
                />
            </CustomModal>
        </div>
    );
};

export default CourseManagement;
