import { injectBaseConstantMethods } from "./BaseConstants";

const StoreAuditStatus = {
    AUDITED: 1,
    RESET: 2,
    MERCHANDISED: 3
};

const displayTextKeys = {
    [StoreAuditStatus.AUDITED]: "Audited",
    [StoreAuditStatus.RESET]: "Reset",
    [StoreAuditStatus.MERCHANDISED]: "Merchandised"
};

const labelClass = {
    [StoreAuditStatus.AUDITED]: "",
    [StoreAuditStatus.RESET]: "",
    [StoreAuditStatus.MERCHANDISED]: ""
};

export default injectBaseConstantMethods(StoreAuditStatus, displayTextKeys, labelClass);
