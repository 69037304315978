const findSkillLevelValueForAPI = (skillLevel) => {
        let skillLevelResult;
        switch(skillLevel){
            case "Advanced/Pro":
                skillLevelResult = "Advanced";
                break;
            case "All Skill Levels":
                skillLevelResult = "Any";
                break;
            default:
                skillLevelResult = skillLevel;
        }
        return skillLevelResult;
    };

export { findSkillLevelValueForAPI }