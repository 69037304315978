import { injectBaseConstantMethods } from "./BaseConstants";

const PROJECT_STATUS = {
    PENDING: 1,
    IN_PROGRESS: 2,
    COMPLETED: 3,
    CANCELLED: 4
};

const displayTextKeys = {
    [PROJECT_STATUS.PENDING]: "Pending",
    [PROJECT_STATUS.IN_PROGRESS]: "InProgress",
    [PROJECT_STATUS.COMPLETED]: "Completed",
    [PROJECT_STATUS.CANCELLED]: "Cancelled"
};

const labelClass = {
    [PROJECT_STATUS.PENDING]: "cs-badge-pending",
    [PROJECT_STATUS.IN_PROGRESS]: "cs-badge-progress",
    [PROJECT_STATUS.COMPLETED]: "cs-badge-completed",
    [PROJECT_STATUS.CANCELLED]: "cs-badge-cancelled"
};

export default injectBaseConstantMethods(PROJECT_STATUS, displayTextKeys, labelClass);
