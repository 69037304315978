import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Col, Container, Row } from "reactstrap";
import { useHistory } from "react-router-dom";

import { Pagination } from "../../components/Pagination";
import { DataTable } from "../../components/DataTable";
import { APP_ROUTES, getParamsAttachedRoute } from "../../helpers/routeHelpers";
import { DeleteUserById, GetUsers, UpdateUsersData } from "../../api/api.service";
import { useLocalPaginatedRequest } from "../../hooks/useLocalPaginatedRequest";
import { PageHeadings, PageTitles, SkillLevelUserManagement, SortOrder } from "../../constants";
import { UserAvatar } from "../../components/Avatar";
import { MODULE_KEYS, getKey } from "../../api/api.keys";
import viewIcon from "../../assets/images/app/common/listing-view-image.svg";
import deleteIcon from "../../assets/images/app/common/listing-delete-image.svg";
import ListingHeader from "../../components/DataTable/ListingHeader";
import { handleFilterChange } from "../../utils/tableUtils";
import APP_CONFIG from "../../APP_CONFIG";
import { getMediaPath } from "../../utils/mediaUtils";
import { truncateString } from "../../utils/commonUtils";
import { useRequestProcessor } from "../../hooks/useRequestProcessors";
import { ConfirmationModal } from "../../components/CustomModal";
import { SUCCESS, ERROR, showToast } from "../../utils/toastUtils";
import { CustomButton } from "../../components/CustomButton";
import { SearchBox } from "../../components/SearchBox";
import { downloadCSVDataDirect } from "../../utils/downloadUtils";
import Config from "../../Config";

const COLUMNS = {
    PROFILE_PICTURE: "Profile Picture",
    FULL_NAME: "Full Name",
    EMAIL: "Email Address",
    ADDRESS: "Address",
    SKILL_LEVEL: "Skill Level",
    ACTION: "Actions"
};

const SORTABLE_COLUMNS = {
    [COLUMNS.FULL_NAME]: "FullName",
    [COLUMNS.EMAIL]: "Email",
    // [COLUMNS.ADDRESS]: "Address",
    [COLUMNS.SKILL_LEVEL]: "SkillLevel"
};
 
const downloadUserFileLink = `${Config.env().API_URL}/static/download/user-details.csv`;
const UserManagement = () => {
    const history = useHistory();

    const [currentSortColumn, setCurrentSortColumn] = useState(null);
    const [currentSortOrder, setCurrentSortOrder] = useState(null);
    const [userID, setUserID] = useState(undefined);
    const [isDeleteUser, setIsDeleteUser] = useState(false);
    const [isFileDownloading, setIsFileDownloading] = useState(false);

    const [isDeletingUser, setIsDeletingUser] = useState(false);

    const [filters, setFilters] = useState({
        [SORTABLE_COLUMNS[COLUMNS.SKILL_LEVEL]]: null
    });

    let { useMutate } = useRequestProcessor();

    // handling article delete API
    let { mutate: userDeleteMutate } = useMutate(
        MODULE_KEYS.USER_MANAGEMENT_LISTING,
        getKey(),
        (data) => DeleteUserById(userID, data)
    );

    const {
        isFetching,
        page,
        total,
        data,
        searchText,
        refetch,
        limit,
        handlePageClick,
        handleSearchChange,
        handleSortingChange
    } = useLocalPaginatedRequest({
        key: getKey([MODULE_KEYS.USER_MANAGEMENT_LISTING]),
        requestFn: GetUsers,
        params: {
            ...(currentSortColumn &&
                currentSortOrder && { Column: currentSortColumn, Direction: currentSortOrder }),
            ...(filters[SORTABLE_COLUMNS[COLUMNS.SKILL_LEVEL]] && {
                [SORTABLE_COLUMNS[COLUMNS.SKILL_LEVEL]]:
                    filters[SORTABLE_COLUMNS[COLUMNS.SKILL_LEVEL]]
            })
        }
    });

    const handleSortClick = (item) => {
        setCurrentSortColumn(item.sortColumn);
        setCurrentSortOrder((prev) =>
            prev === SortOrder.ASC || !prev ? SortOrder.DESC : SortOrder.ASC
        );
    };

    const deleteClickHandler = (Id) => {
        setUserID(Id);
        setIsDeleteUser(true);
    };

    const handleDeleteUser = () => {
        setIsDeletingUser(true);
        // Api call here
        userDeleteMutate(
            {},
            {
                onSuccess: async (res) => {
                    setIsDeletingUser(false);
                    setIsDeleteUser(false);
                    showToast(SUCCESS, "User has been deleted successfully");
                },
                onError: (err) => {
                    setIsDeletingUser(false);
                    setIsDeleteUser(false);
                    showToast(ERROR, err?.Message);
                }
            }
        );
    };

    const handleDownloadUsers = async () => {
        try{
            setIsFileDownloading(true);
            let resp = await UpdateUsersData();
            downloadCSVDataDirect(downloadUserFileLink);
            showToast(SUCCESS, "File has been downloaded successfully");
        }catch(err){
        }finally{
            setIsFileDownloading(false);
        }
    }

    return (
        <div className="page-content">
            <Helmet>
                <title>{PageTitles.USER_MANAGEMENT}</title>
            </Helmet>
            <Container fluid>
                <Row>
                    <Col sm="12" md="3" lg="3">
                        <SearchBox
                            className="me-3 w-50"
                            value={searchText}
                            onChange={handleSearchChange}
                        />
                    </Col>
                    <Col sm="12" md="6" lg="6" className="my-2"></Col>
                    <Col sm="12" md="3" lg="3" align="right">
                        <CustomButton
                            title="Download"
                            color="primary"
                            className="custom-btn-lg w-100 max-width-percent-modified"
                            onClick={handleDownloadUsers}
                            loading={isFileDownloading}
                            disabled={isFileDownloading} 
                        />
                    </Col>
                </Row>

                <DataTable
                    tableContainerClass="mt-3"
                    loading={isFetching}
                    data={data}
                    currentSortOrder={currentSortOrder}
                    currentSortColumn={currentSortColumn}
                    onSortClick={handleSortClick}
                    config={[
                        {
                            title: COLUMNS.PROFILE_PICTURE,
                            render: (data) => (
                                <UserAvatar
                                    imgSrc={
                                        getMediaPath(data.ProfileImage) ||
                                        APP_CONFIG.IMAGES.AVATAR_PLACEHOLDER
                                    }
                                    altText={data.FullName}
                                />
                            )
                        },

                        {
                            sortColumn: SORTABLE_COLUMNS[COLUMNS.FULL_NAME],
                            isSortable: true,
                            title: COLUMNS.FULL_NAME,
                            render: (data) => (
                                <div className="py-3">
                                    {truncateString(data.FullName, 25) || "-"}
                                </div>
                            )
                        },

                        {
                            sortColumn: SORTABLE_COLUMNS[COLUMNS.EMAIL],
                            isSortable: true,
                            title: COLUMNS.EMAIL,
                            render: (data) => (
                                <div className="py-3">{truncateString(data.Email, 30) || "-"}</div>
                            )
                        },
                        {
                            // sortColumn: SORTABLE_COLUMNS[COLUMNS.ADDRESS],
                            // isSortable: true,
                            title: COLUMNS.ADDRESS,
                            render: (data) => (
                                <div className="py-3">
                                    {truncateString(data?.HomeCourse?.Address, 25) || "-"}
                                </div>
                            )
                        },
                        {
                            isFilterable: true,
                            filterConstants: SkillLevelUserManagement,
                            filterValue: filters[SORTABLE_COLUMNS[COLUMNS.SKILL_LEVEL]],
                            title: COLUMNS.SKILL_LEVEL,
                            render: (data) => <div className="py-3">{data.SkillLevel || "-"}</div>,
                            onFilterSelect: (value) =>
                                handleFilterChange(
                                    SORTABLE_COLUMNS[COLUMNS.SKILL_LEVEL],
                                    value,
                                    handlePageClick,
                                    setFilters
                                )
                        },
                        {
                            title: COLUMNS.ACTION,
                            render: (data) => {
                                return (
                                    <div className="d-flex py-3">
                                        <img
                                            className="me-3"
                                            src={viewIcon}
                                            role="button"
                                            onClick={() =>
                                                history.push(
                                                    getParamsAttachedRoute(
                                                        APP_ROUTES.USER_MANAGEMENT_DETAILS,
                                                        {
                                                            userId: data?.Id || 1
                                                        }
                                                    )
                                                )
                                            }
                                        />
                                        <img
                                            src={deleteIcon}
                                            role="button"
                                            alt={"delete icon"}
                                            onClick={() => deleteClickHandler(data?.Id)}
                                        />
                                    </div>
                                );
                            }
                        }
                    ]}
                />

                <Pagination
                    onPageClick={handlePageClick}
                    currentPage={page}
                    totalData={total}
                    handleChange={(e) => handleSortingChange(e.target.value)}
                    rowLimit={limit}
                />

                <ConfirmationModal
                    isOpen={isDeleteUser}
                    onYes={handleDeleteUser}
                    yesLoading={isDeletingUser}
                    size="md"
                    onNo={() => setIsDeleteUser(false)}
                />
            </Container>
        </div>
    );
};

export default UserManagement;
