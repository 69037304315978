import { injectBaseConstantMethods } from "./BaseConstants";

const SkillLevelUserManagement = {
    // Any: "Any",
    Beginner: "Beginner",
    Intermediate: "Intermediate",
    Advanced: "Advanced",
    Pro: "Pro"
};

const displayTextKeys = {
    // [SkillLevelUserManagement.Any]: "All Skill Levels",
    [SkillLevelUserManagement.Advanced]: "Advanced",
    [SkillLevelUserManagement.Intermediate]: "Intermediate",
    [SkillLevelUserManagement.Beginner]: "Beginner",
    [SkillLevelUserManagement.Pro]: "Pro"
};

const labelClass = {
    // [SkillLevelUserManagement.Any]: "skill-any",
    [SkillLevelUserManagement.Advanced]: "skill-advance",
    [SkillLevelUserManagement.Intermediate]: "skill-intermediate",
    [SkillLevelUserManagement.Beginner]: "skill-beginner",
    [SkillLevelUserManagement.Pro]: "Pro"
}; 

export default injectBaseConstantMethods(SkillLevelUserManagement, displayTextKeys, labelClass);