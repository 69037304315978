import { injectBaseConstantMethods } from "./BaseConstants";

export const JOBS_STATUS = {
    Open: 1, //done
    Pending: 2, // partially done
    Assigned: 3, //done
    InProgress: 4, //done
    Submitted: 5, // half done
    Completed: 6, // half done
    ForReview: 7, //half done
    Canceled: 8,
    Expired: 10
};

const displayTextKeys = {
    [JOBS_STATUS.Open]: "Open",
    [JOBS_STATUS.Pending]: "Pending",
    [JOBS_STATUS.Assigned]: "Assigned",
    [JOBS_STATUS.InProgress]: "InProgress",
    [JOBS_STATUS.Submitted]: "Submitted",
    [JOBS_STATUS.Completed]: "Completed",
    [JOBS_STATUS.ForReview]: "ForReview",
    [JOBS_STATUS.Canceled]: "Cancelled",
    [JOBS_STATUS.Expired]: "Expired"
};

const labelClass = {
    [JOBS_STATUS.Open]: "cs-badge-open",
    [JOBS_STATUS.Pending]: "cs-badge-pending",
    [JOBS_STATUS.Assigned]: "cs-badge-assigned",
    [JOBS_STATUS.InProgress]: "cs-badge-progress",
    [JOBS_STATUS.Submitted]: "",
    [JOBS_STATUS.Completed]: "cs-badge-completed",
    [JOBS_STATUS.ForReview]: "cs-badge-review",
    [JOBS_STATUS.Canceled]: "",
    [JOBS_STATUS.Expired]: "cs-badge-expired"
};

export default injectBaseConstantMethods(JOBS_STATUS, displayTextKeys, labelClass);
