import * as Yup from "yup";
import { LABLES, NAMES } from "./CourseFieldsName";
let {
    COURSE_NAME,
    ADDRESS,
    CITY,
    STATE,
    // COURSE_FEE,
    // PARKING,
    BASKETS,
    TEE_PADS,
    NOTES,
    OPERATION_TIMINGS,
    COURSE_PROFILE_LINK
} = LABLES;

export const initialValues = {
    [NAMES[COURSE_NAME]]: "",
    [NAMES[ADDRESS]]: "",
    [NAMES[CITY]]: "",
    [NAMES[STATE]]: "",
    // [NAMES[PARKING]]: "",
    // [NAMES[COURSE_FEE]]: "",
    [NAMES[BASKETS]]: "",
    [NAMES[TEE_PADS]]: "",
    [NAMES[NOTES]]: "",
    [NAMES[COURSE_PROFILE_LINK]]: ""
    // [NAMES[OPERATION_TIMINGS]]: ""
};

export const validationSchema = Yup.object().shape({
    [NAMES[COURSE_NAME]]: Yup.string()
        .required("Please enter the course name.")
        .matches(
            /^[a-zA-Z0-9_.,!'"?/$-/\s]+$/,
            `Course Name should only contain alphabets, numbers, and any of the following characters: , . ! - ' " _ / $ ?`
        )
        .max(200, "Please enter between 1 and 200 characters."),
    [NAMES[ADDRESS]]: Yup.string().required("Please enter the address."),
    [NAMES[CITY]]: Yup.string()
        .required("Please enter the city.")
        .max(50, "Please enter between 1 and 50 characters."),
    [NAMES[STATE]]: Yup.string(),
    // [NAMES[PARKING]]: Yup.string()
    //     .required("Please enter the parking.")
    //     .matches(
    //         /^[a-zA-Z0-9 _.,!'"?/$-/\s]+$/,
    //         `Parking should only contain alphabets, numbers, and any of the following characters: , . ! - ' " _ / $ ?`
    //     )
    //     .max(100, "Please enter between 1 and 100 characters."),
    // [NAMES[COURSE_FEE]]: Yup.string(),
    // .required("Please enter the course fee.")
    // .matches(/^[0-9 ]+$/, "Please enter numbers only.")
    // .max(5, "Please enter between 1 and 5 digits."),
    [NAMES[BASKETS]]: Yup.string()
        .required("Please enter the baskets.")
        .matches(
            /^[a-zA-Z0-9 _.,!'"?/$-/\s]+$/,
            `Baskets should only contain alphabets, numbers, and any of the following characters: , . ! - ' " _ / $ ?`
        )
        .max(100, "Please enter between 1 and 100 characters."),
    [NAMES[TEE_PADS]]: Yup.string()
        .required("Please enter the teepads.")
        .matches(
            /^[a-zA-Z0-9 _.,!'"?/$-/\s]+$/,
            `Teepads should only contain alphabets, numbers, and any of the following characters: , . ! - ' " _ / $ ?`
        )
        .max(100, "Please enter between 1 and 100 characters."),
    [NAMES[NOTES]]: Yup.string().required("Please enter the notes.")
        // .matches(/^[a-zA-Z0-9 _.,!'"?/$-/\s]+$/, `Notes should only contain alphabets, numbers, and any of the following characters: , . ! - ' " _ / $ ?`)
        .max(500, "Please enter up to 500 characters."),
    [NAMES[COURSE_PROFILE_LINK]]: Yup.string()
        // .required("Please enter the PDGA Course Profile Link.")
        .matches(/\b(http|https):\/\//, "Url must start with https:// or http://")
        .min(5, "PDGA Course Profile Link should be minimum 5 characters long")
        .max(256, "Please enter between 5 and 256 characters.")
        .url("Kindly enter valid URL.")
});
