import React from "react";
import { useFormik } from "formik";
import { Card, CardBody, Row, Col } from "reactstrap";

import { CustomText } from "../../components/CustomText";
import { TextInput } from "../../components/Input";
import { CustomButton } from "../../components/CustomButton";
import { ImageBox } from "../../components/ImageBox";
import { adManagementFormValidationSchema, validationMessagesImage } from "./FormValidations";
import FormikErrorText from "../../components/FormikErrorText/FormikErrorText";
import { monthYear } from "../../utils/dateUtils";
import { EditableDetail } from "../../components/EditableDetail";
import { convertDateTime, dateTimeFormat } from "../../utils/dateFormatUtils";

let EDIT_ICON = <span className="bx bx-edit-alt  icon-grey"></span>;
const ContentDataStructureUI = ({
    isUpdate = false,
    isDetailView = false,
    FIELDS_TITLE = {},
    FIELDS_NAME = {},
    onCancel = () => {},
    onSave = () => {},
    onEdit = () => {},
    setContentImage = () => {},
    isFormSubmitting = false,
    children,
    data = {}
}) => {
    let {
        ADVERTISEMENT_LINK,
        ADVERTISEMENT_NAME,
        START_DATE,
        END_DATE,
        ADVERTISERS_NAME,
        ADVERTISERS_EMAIL,
        ADD_IMGAE
    } = FIELDS_TITLE;

    let responsiveInputCols = isUpdate ? "6" : "4";

    //For Alpha
    const defaultFormData = {
        [FIELDS_NAME[ADVERTISEMENT_LINK]]: data?.Url || "",
        [FIELDS_NAME[ADVERTISEMENT_NAME]]: data?.Title || "",
        [FIELDS_NAME[START_DATE]]: data?.StartAt || "",
        [FIELDS_NAME[END_DATE]]: data?.EndAt || "",
        [FIELDS_NAME[ADVERTISERS_NAME]]: data?.AdvertiserName || "",
        [FIELDS_NAME[ADVERTISERS_EMAIL]]: data?.AdvertiserEmail || ""
    };

    // formik validations
    const formik = useFormik({
        initialValues: defaultFormData,
        enableReinitialize: true,
        validationSchema: adManagementFormValidationSchema,
        onSubmit: (values) => {
            onSave(values);
        }
    });

    return (
        <Card className="cutom-card card-shadow">
            <form onSubmit={formik.handleSubmit}>
                <CardBody>
                    {children}
                    <Row>
                        <Col sm="12" md={responsiveInputCols} lg={responsiveInputCols}>
                            <EditableDetail
                                inputClasses="content-input"
                                isUpdate={isUpdate}
                                isDetails={isDetailView}
                                title={ADVERTISEMENT_LINK}
                                name={FIELDS_NAME[ADVERTISEMENT_LINK]}
                                value={formik.values[FIELDS_NAME[ADVERTISEMENT_LINK]]}
                                onChange={formik.handleChange}
                                onBlur={formik.handleChange}
                            />
                            <FormikErrorText
                                formikInstance={formik}
                                fieldName={[FIELDS_NAME[ADVERTISEMENT_LINK]]}
                            />
                        </Col>
                        <Col sm="12" md={responsiveInputCols} lg={responsiveInputCols} className="mt-4 mt-md-0">
                            <EditableDetail
                                inputClasses="content-input"
                                isUpdate={isUpdate}
                                isDetails={isDetailView}
                                title={ADVERTISEMENT_NAME}
                                name={FIELDS_NAME[ADVERTISEMENT_NAME]}
                                value={formik.values[FIELDS_NAME[ADVERTISEMENT_NAME]]}
                                onChange={formik.handleChange}
                                onBlur={formik.handleChange}
                            />
                            <FormikErrorText
                                formikInstance={formik}
                                fieldName={[FIELDS_NAME[ADVERTISEMENT_NAME]]}
                            />
                        </Col>{" "}
                        {isUpdate ? null : (
                            <Col sm="12" md="4" lg="4" className="mt-4 mt-md-0">
                                <CustomText
                                    title={START_DATE}
                                    description={
                                        convertDateTime({
                                            date: data?.StartAt,
                                            customFormat: dateTimeFormat.appDateTimeFormat
                                        }) || "-"
                                       
                                    }
                                    className="text-margin"
                                />
                            </Col> 
                        )}
                    </Row>

                    <Row className="mt-4 mb-0 mb-md-5 mt-md-5">
                        <Col sm="12" md={responsiveInputCols} lg={responsiveInputCols}>
                            <EditableDetail
                                inputClasses="content-input"
                                isUpdate={isUpdate}
                                isDetails={isDetailView}
                                title={ADVERTISERS_NAME}
                                name={FIELDS_NAME[ADVERTISERS_NAME]}
                                value={formik.values[FIELDS_NAME[ADVERTISERS_NAME]]}
                                onChange={formik.handleChange}
                                onBlur={formik.handleChange}
                            />
                            <FormikErrorText
                                formikInstance={formik}
                                fieldName={[FIELDS_NAME[ADVERTISERS_NAME]]}
                            />
                        </Col>
                        <Col sm="12" md={responsiveInputCols} lg={responsiveInputCols} className="mt-4 mt-md-0">
                            <EditableDetail
                                inputClasses="content-input"
                                isUpdate={isUpdate}
                                isDetails={isDetailView}
                                title={ADVERTISERS_EMAIL}
                                name={FIELDS_NAME[ADVERTISERS_EMAIL]}
                                value={formik.values[FIELDS_NAME[ADVERTISERS_EMAIL]]}
                                onChange={formik.handleChange}
                                onBlur={formik.handleChange}
                            />
                            <FormikErrorText
                                formikInstance={formik}
                                fieldName={[FIELDS_NAME[ADVERTISERS_EMAIL]]}
                            />
                        </Col>
                    </Row>

                    <Row className="mt-4 mb-4 mb-md-0 mt-md-0">
                        <Col sm="12" md="12" lg="12">
                            <div className="text-margin" style={{ height: "200px", width: "100%" }}>
                                <h5 className="fw-bold mb-1 clr-theme-primary ad-mang-edit-btn-margin">
                                    {ADD_IMGAE}
                                </h5>
                                <ImageBox
                                    containerClasses="w-100 h-100"
                                    isEditable={isUpdate}
                                    imgUrl={data?.Media?.OriginalUrl || ""}
                                    onImageUpload={setContentImage}
                                    imageErrors={validationMessagesImage}
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row className="py-lg-5 py-md-5 py-3 ad-btn-responsiveness">
                        <Col sm="12" md="12" lg="12" className="ad-mang-edit-btn-margin ms-0 ms-md-3">
                            {isDetailView && (
                                <CustomButton
                                    title="Edit"
                                    color="primary"
                                    className="custom-btn-lg mt-3 ad-btn"
                                    onClick={onEdit}
                                    type={"button"}
                                />
                            )}
                            {isUpdate && (
                                <div className="mt-5 ad-btns-containers">
                                    <CustomButton
                                        title="Save Changes"
                                        color="primary"
                                        className="custom-btn-lg ad-btn"
                                        type={"submit"}
                                        loading={isFormSubmitting}
                                    />
                                    <CustomButton
                                        title="Cancel"
                                        outline
                                        className="custom-btn-lg-secondary ms-0 ms-md-2 mt-3 mt-md-0 ad-btn "
                                        onClick={onCancel}
                                        type={"button"}
                                        isDisabled={isFormSubmitting}
                                    />
                                </div>
                            )}
                        </Col>
                    </Row>
                </CardBody>
            </form>
        </Card>
    );
};

export default ContentDataStructureUI;
