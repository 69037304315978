import { injectBaseConstantMethods } from "./BaseConstants";

const StoreAuditConst = {
    YES: "Y",
    No: "N"
};

const displayTextKeys = {
    [StoreAuditConst.YES]: "Yes",
    [StoreAuditConst.No]: "No"
};

const labelClass = {
    [StoreAuditConst.YES]: "badge-soft-success",
    [StoreAuditConst.No]: "badge-soft-danger"
};

export default injectBaseConstantMethods(StoreAuditConst, displayTextKeys, labelClass);
